export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'CV',
        url: '/CV',
        cName: 'nav-links'
    },
]

export const DropdownItems = [
    {
        title: 'Hole in the Wall',
        url: '/Hole-In-The-Wall',
        cName: 'dropdown-links'
    },
    {
        title: 'VR Firepit',
        url: '/VR-Firepit',
        cName: 'dropdown-links'
    },
    {
        title: 'VR Data Visualisation',
        url: '/VR-Data-Visualisation',
        cName: 'dropdown-links'
    },
    {
        title: 'Ball Physics',
        url: '/Ball-Physics',
        cName: 'dropdown-links'
    },
    {
        title: 'Banking App',
        url: '/Banking-App',
        cName: 'dropdown-links'
    },
    {
        title: 'Macabre Maze',
        url: '/Macabre-Maze',
        cName: 'dropdown-links'
    },
    {
        title: '3D Renderer',
        url: '/3D-Renderer',
        cName: 'dropdown-links'
    },
    {
        title: '3D Modelling',
        url: '/3D-Modelling',
        cName: 'dropdown-links'
    },
    {
        title: 'Stack',
        url: '/Stack',
        cName: 'dropdown-links'
    },
    {
        title: 'Rooms',
        url: '/Rooms',
        cName: 'dropdown-links'
    },
]